class EventBus{
	
	static listeners:any = {};

	static addEventListener(evt, callback){

		if(!EventBus.listeners[evt]){
			EventBus.listeners[evt] = [];
		}

		if(EventBus.listeners[evt].indexOf(callback) == -1){
			EventBus.listeners[evt].push(callback);
		}
	}

	static removeEventListener(evt, callback){

		var i = EventBus.listeners[evt].indexOf(callback)
		if(i > -1){
			EventBus.listeners[evt].splice(i, 1);
			if(EventBus.listeners[evt].length == 0){
				EventBus.listeners[evt] = null;
			}
		}
	}	

	static dispatchEvent(evt, data=null){

		var listeners = EventBus.listeners[evt];
		if(listeners){

			
			for(var i = 0; i<listeners.length; i++){
				listeners[i](data);
			}
		}
	}
}