module Nickel{
	
	export class Component{

		//globals
		public data:any;
		public container:any;
		public content:any;
		public rivets:any;
		public delegate:any;
		public onStage:boolean  =false;
		public displayTimeout:any;
		public hideDelay:any = 400;
		public showDelay:number = 500;
		//child stuff
		// public childToDelete:any;
		// public children:any = [];
		// public activeChild:any;

		constructor(container, data, delegate){

			this.container = container;
			this.delegate = delegate;
			this.data= data;

			this.viewLoaded(this.container);
		}

		//created the rivets data binding and binds all Level generic events
		public viewLoaded(e){

			this.content = e;

			if(this.container) this.container.append(this.content);
			this.rivets = rivets.bind(this.content, {
				"data":this.data,
				"controller":this
			});

			$(window).bind("resize", $.proxy(this.resize, this));
			this.resize(null);
		}	

		public showMe(delay?){
			
			var dly = (delay) ? delay : this.showDelay;	
			this.content.css('display', 'block');
			this.onStage = true;

			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout( ()=>{
				this.showPageContent();
			}, dly);

		}

		public hideMe(){

			this.hidePageContent();
				
			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout( ()=>{
				this.onStage = false;	
				this.content.css('display', 'none');
			}, this.hideDelay);

		}

		public showPageContent(){
			this.content.removeClass('hideContent');
			this.content.addClass('showContent');
		}

		/*
		Toggles the show and hide css classes to hide the content
		*/
		public hidePageContent(){
			this.content.removeClass('showContent');
			this.content.addClass('hideContent');
		}

		//removed this classes frontend from the UI
		public killMe(){

			this.rivets.unbind();

			this.content.remove();
			this.content = null;
		}

		//OVERRIDE ME!
		public resize(e){
			
		}
	}
}