
module Nickel{
	
	export class AboutView extends View{

		constructor(container, data, id){

			super(container, data, id);

			if(Main.ourOS.os == "Android"){
				this.content.find('#btnDownloadiOS').hide();
			}else if(Main.ourOS.os == "iOS"){
				this.content.find('#btnDownloadAndroid').hide();
			}

			$(window).bind('resize orientationchange', $.proxy(this.resize, this));
			this.resize();

			$('.googleUrl').attr('href', Main.appUrlGoogle);
			$('.appleUrl').attr('href', Main.appUrlApple);
			
			$('.downloadLink').attr('target', Main.appTarg);
		}

		public resize(){

			this.content.css('height', $(window).height());
			// this.content.css('top', $("#landingView").height());
			// if($(window).width() < 700 && this.onStage){
			// 	Utils.pushState("/");
			// }
		}
	}
}