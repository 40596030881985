
class AjaxRequest extends Scaffold
{
	public url:string = null;
	public data:any = {};
	public successCallback:any = null;
	public errorCallback:any = null;
	public loader:Loader = null;

	public constructor(data:any={},success:any=null,error:any=null,loader:Loader=null,url:string= 'https://cms.imposium.com/api/1.0')
	{
		super();
		this.url = url;
		this.data = data;
		this.successCallback = success;
		this.errorCallback = error;
		this.loader = loader;
	}
}

class Ajax extends Scaffold
{
	static formSubmit(form:any, onComplete:any=null, onProgress:any=null, loader:Loader=null){
		if(!form.data('ajaxified')){
			form.data('ajaxified','1');
			form.ajaxForm({
				complete: function (xhr) {
					if(loader){
						loader.hide();
					}
					if(onComplete){
						var responseObj = eval("(" + xhr.responseText + ")");
						onComplete(responseObj);
					}
				},
				uploadProgress:function(evt, pos, total, perc){
					if(onProgress){
						onProgress(evt, pos, total, perc);
					}
				}
			});
		}
		if(loader){
			loader.show();
		}
		form.submit();
		return form;
	}

	static post(request:AjaxRequest)
	{
		if(request.loader){
			request.loader.show();
		}
		$.ajax({
		    type: 'POST',
		    url: request.url,
		    data: request.data,
		    dataType: 'json',
		    success: function(response) {
		    	if(request.loader){
		    		request.loader.hide();
		    	}
		    	Ajax.checkExpiredSession(response.bind.task);
		    	if(request.successCallback){
		    		request.successCallback(response);
		    	}
		    },
		    error: function(jqXHR,textStatus,errorThrown){
		    	if(request.loader){
		    		request.loader.hide();
		    	}
		    	if(request.errorCallback){
		    		request.errorCallback(jqXHR.responseText);
		    	}
		    }
		});
	}

	static checkExpiredSession(task:string){
		if(task == 'expired'){
			Ajax.expireSession();
		}
	}

	static expireSession(){
		window.location.href = "index.php?id=lostsession";
	}
}
