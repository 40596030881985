/*
Helper functions accessable by all other .ts files
*/
class Utils{

	static isNumeric(n) {
	  return !isNaN(parseFloat(n)) && isFinite(n);
	}

	static generateUUID(){
	    var d = new Date().getTime();
	    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
	        var r = (d + Math.random()*16)%16 | 0;
	        d = Math.floor(d/16);
	        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
	    });
	    return uuid;
	}

	static ajaxifyForm(form:any, onComplete:any=false){
		if(!form.data('ajaxified')){
			form.data('ajaxified','1');
			form.ajaxForm({
				complete: function (xhr) {
					if(onComplete){
						var responseObj = eval("(" + xhr.responseText + ")");
						onComplete(responseObj);
					}
				}
			});
		}
		return form;
	}

	static clientTimezone(date){
		return date.addHours(-(new Date().getTimezoneOffset()/60));
	}

	static composite( under, alphaUnder, over, alphaOver){
		return Math.round( (under  * alphaUnder) + (over * alphaOver));
	}

	static multiply(topValue, bottomValue){
	    return topValue * bottomValue / 255;
	}



	      static overlay(a, b) {
	        a /= 255;
	        b /= 255;
	        var result = 0;

	        if (a < 0.5) result = 2 * a * b;
	        else result = 1 - 2 * (1 - a) * (1 - b);

	        return Math.min(255, Math.max(0, result * 255 | 0));
	      }

	      static hardLight(a, b) {
	        return Utils.overlay(b, a);
	      }

	      static softLight(a, b) {
	        a /= 255;
	        b /= 255;

	        var v = (1 - 2 * b) * (a * a) + 2 * b * a;
	        return Utils.clamp(v * 255, 0, 255);
	      }

	      static dodge(a, b) {
	        return Math.min(256 * a / (255 - b + 1), 255);
	      }

	      static burn(a, b) {
	        return 255 - Math.min(256 * (255 - a) / (b + 1), 255);
	      }


	      static divide(a, b) {
	        return Math.min(256 * a / (b + 1), 255);
	      }

	      static screen(a, b) {
	        return 255 - (255 - b) * (255 - a) / 255;
	      }

	      static grainExtract(a, b) {
	        return Utils.clamp(a - b + 128, 0, 255);
	      }

	      static grainMerge(a, b) {
	        return Utils.clamp(a + b - 128, 0, 255);
	      }

	      static difference(a, b) {
	        return Math.abs(a - b);
	      }

	      static addition(a, b) {
	        return Math.min(a + b, 255);
	      }

	      static substract(a, b) {
	        return Math.max(a - b, 0);
	      }

	      static darkenOnly(a, b) {
	        return Math.min(a, b);
	      }

	      static lightenOnly(a, b) {
	        return Math.max(a, b);
	      }

	      static color(a, b) {
	        var aHSL = Utils.rgbToHsl(a);
	        var bHSL = Utils.rgbToHsl(b);

	        return Utils.hslToRgb(bHSL[0], bHSL[1], aHSL[2]);
	      }

	      static hue(a, b) {
	        var aHSV = Utils.rgbToHsv(a);
	        var bHSV = Utils.rgbToHsv(b);

	        if (!bHSV[1]) return Utils.hsvToRgb(aHSV[0], aHSV[1], aHSV[2]);
	        else return Utils.hsvToRgb(bHSV[0], aHSV[1], aHSV[2]);
	      }

	      static value(a, b) {
	        var aHSV = Utils.rgbToHsv(a);
	        var bHSV = Utils.rgbToHsv(b);

	        return Utils.hsvToRgb(aHSV[0], aHSV[1], bHSV[2]);
	      }

	      static saturation(a, b) {
	        var aHSV = Utils.rgbToHsv(a);
	        var bHSV = Utils.rgbToHsv(b);

	        return Utils.hsvToRgb(aHSV[0], bHSV[1], aHSV[2]);
	      }

	      static rgbToHsl(r?, g?, b?) {

            if (r instanceof Array) {
              b = r[2];
              g = r[1];
              r = r[0];
            }

            r /= 255, g /= 255, b /= 255;
            var max = Math.max(r, g, b),
              min = Math.min(r, g, b);
            var h, s, l = (max + min) / 2;

            if (max == min) {
              h = s = 0; // achromatic
            } else {
              var d = max - min;
              s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
              switch (max) {
                case r:
                  h = (g - b) / d + (g < b ? 6 : 0);
                  break;
                case g:
                  h = (b - r) / d + 2;
                  break;
                case b:
                  h = (r - g) / d + 4;
                  break;
              }
              h /= 6;
            }

            return [h, s, l];
          }

          static hslToRgb(h?, s?, l?) {
            var r, g, b;

            if (s == 0) {
              r = g = b = l; // achromatic
            } else {
              function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
              }

              var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
              var p = 2 * l - q;
              r = hue2rgb(p, q, h + 1 / 3);
              g = hue2rgb(p, q, h);
              b = hue2rgb(p, q, h - 1 / 3);
            }

            return [r * 255 | 0, g * 255 | 0, b * 255 | 0];
          }

	      static rgbToHsv(r?, g?, b?) {
            if (r instanceof Array) {
              b = r[2];
              g = r[1];
              r = r[0];
            }

            r = r / 255, g = g / 255, b = b / 255;
            var max = Math.max(r, g, b),
              min = Math.min(r, g, b);
            var h, s, v = max;

            var d = max - min;
            s = max == 0 ? 0 : d / max;

            if (max == min) {
              h = 0; // achromatic
            } else {
              switch (max) {
                case r:
                  h = (g - b) / d + (g < b ? 6 : 0);
                  break;
                case g:
                  h = (b - r) / d + 2;
                  break;
                case b:
                  h = (r - g) / d + 4;
                  break;
              }
              h /= 6;
            }

            return [h, s, v];
          }

          static hsvToRgb(h?, s?, v?) {
            var r, g, b;

            var i = Math.floor(h * 6);
            var f = h * 6 - i;
            var p = v * (1 - s);
            var q = v * (1 - f * s);
            var t = v * (1 - (1 - f) * s);

            switch (i % 6) {
              case 0:
                r = v, g = t, b = p;
                break;
              case 1:
                r = q, g = v, b = p;
                break;
              case 2:
                r = p, g = v, b = t;
                break;
              case 3:
                r = p, g = q, b = v;
                break;
              case 4:
                r = t, g = p, b = v;
                break;
              case 5:
                r = v, g = p, b = q;
                break;
            }

            return [r * 255 | 0, g * 255 | 0, b * 255 | 0];
          }
	
	/* --------------------------------------------------------*/
	/* ------------------------ HELPERS -----------------------*/
	/* --------------------------------------------------------*/

	static isOdd(num):boolean{
		return (num % 2) == 1
	}
	/*
	Used by the create overlay function to achieve the multiply blending in the canvas
	*/
	
		
	static step(e, t) {
	    return (t >= e) ? 1 : 0;
	}

	static minWithSign(v, m) {
	    var s = (v < 0) ? -1 : 1;
	    var vv = Math.min(Math.abs(v), m);
	    return vv * s;
	}

	static maxWithSign(v, m) {
	    var s = (v < 0) ? -1 : 1;
	    var vv = Math.max(Math.abs(v), m);
	    return vv * s;
	}

	static clamp(s, e, t) {
	    if (t < s) return s;
	    if (t > e) return e;
	    return t;
	}

	static smoothStep(e0, e1, t) {
	    if (t <= e0) return e0;
	    if (t >= e1) return e1;

	    t = (t - e0) / (e1 - e0);

	    return e0 + (e1 - e0) * (3 * t * t - 2 * t * t * t);
	}

	static easeQuadOut(e0, e1, t) {
	    return e0 + (e1 - e0) * (t * ( 2 - t ));
	}

	/*
	opens a window centered in the screen
	*/
	static openWindow(url,width,height)
	{
		var windowSize = { 'width': width, 'height': height, 'left': (screen.width/2)-(width/2), 'top': (screen.height/2)-(height/2 + 100) }
		var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
		windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
		window.open(url,''+new Date().getTime()+'',windowFeatures);
	}

	/*
	helper for ajax requests
	*/
	static ajax(data,successCallback=null,errorCallback=null,ajaxUrl=null){
		$.ajax({
		    type: 'POST',
		    url: 'ajax.cms.php',
		    data: data,
		    dataType: 'json',
		    success: function(response){
		    	if(successCallback){
		    		successCallback(response);
		    	}
		    },
		    error: function(jqXHR,textStatus,errorThrown){
		    	if(errorCallback){
		    		errorCallback(jqXHR.responseText);
		    	}
		    }
		});
	}
	
	/*
	Pushes a string to the url
	*/	
	static pushState(state){
		// History['pushState']({state:1}, Main.config.pageTitle, state);
		History['pushState']({state:1}, Main.config.title, state);
	}

	static replaceState(state){
		// History.replaceState(null, null, state);
	}

	/*
	Returns either iOS, Android, or Unknown, and the version number
	*/
	static detectMobileOS(){

		var mobileOS;    
		var mobileOSver; 
		var ua = navigator.userAgent;
		var uaindex;

		  // determine OS
		  if ( ua.match(/iPad/i) || ua.match(/iPhone/i) ){
		    mobileOS = 'iOS';
		    uaindex  = ua.indexOf( 'OS ' );
		  }else if ( ua.match(/Android/i) ){
		    mobileOS = 'Android';
		    uaindex  = ua.indexOf( 'Android ' );
		  }else{
		    mobileOS = 'unknown';
		  }

		  // determine version
		  if ( mobileOS === 'iOS'  &&  uaindex > -1 ){
		    mobileOSver = ua.substr( uaindex + 3, 3 ).replace( '_', '.' );
		  } else if ( mobileOS === 'Android'  &&  uaindex > -1 ){
		    mobileOSver = ua.substr( uaindex + 8, 3 );
		  }else{
		    mobileOSver = 'unknown';
		  }

		  var num = Number(mobileOSver);
		  return {"os":mobileOS, "ver":num};
	}



	/* --------------------------------------------------------*/
	/* ------------------------ COOKIES -----------------------*/
	/* --------------------------------------------------------*/

	/*
	Sets a cookie to the document object
	*/
	static setCookie(c_name,value,exdays){
		var exdate=new Date();
		exdate.setDate(exdate.getDate() + exdays);
		var c_value = value + ((exdays==null) ? "" : "; expires="+exdate.toUTCString()) + ";path=/";
		document.cookie=c_name + "=" + c_value;
	}

	/*
	Retrives a cookie from the document object using the cookies name
	*/
	static getCookie(c_name){
		var c_value = document.cookie;
		var c_start = c_value.indexOf(" " + c_name + "=");
		if (c_start == -1)
		  {
		  c_start = c_value.indexOf(c_name + "=");
		  }
		if (c_start == -1)
		  {
		  c_value = null;
		  }
		else
		  {
		  c_start = c_value.indexOf("=", c_start) + 1;
		  var c_end = c_value.indexOf(";", c_start);
		  if (c_end == -1)
		  {
		c_end = c_value.length;
		}
		c_value = c_value.substring(c_start,c_end);
		}
		return c_value;
	}



	/* --------------------------------------------------------*/
	/* ---------------------- VALIDATION ----------------------*/
	/* --------------------------------------------------------*/

	/*
	Validates an email address
	*/
	static realEmail(addressToTest) {
    	var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
   		return regPattern.test(addressToTest);
	}

	/* --------------------------------------------------------*/
	/* ------------------------- MATH -------------------------*/
	/* --------------------------------------------------------*/

	/*
	Convert degrees to radians
	*/
	static degreesToRadians(degrees:number):number
    {
        return degrees * Math.PI / 180;
    }

    /*
    convert radians to degrees
    */
    static radiansToDegrees(radians:number):number
    {
        return radians * 180 / Math.PI;
    }

	/*
	calculates the distance between two point's : p = {x:1, y:2}
	*/
	static lineDistance( point1, point2 ){
	    var xs = 0;
	    var ys = 0;
	    xs = point2.x - point1.x;
	    xs = xs * xs;
	    ys = point2.y - point1.y;
	    ys = ys * ys;
	    return Math.sqrt( xs + ys );
	}

	/*
	calculates the angle in degrees between two points
	*/
	static calcAngle(x1, x2, y1, y2)
	{
		var calcAngle = Math.atan2(x1-x2,y1-y2)*(180/Math.PI);	
		if(calcAngle < 0)	
		calcAngle = Math.abs(calcAngle);
		else
		calcAngle = 360 - calcAngle;		
		return calcAngle;
	}

	/*
	returns a number between or on the two numbers specified
	*/
	static randomFromInterval(from,to){
	    return Math.floor(Math.random()*(to-from+1)+from);
	}

	/* --------------------------------------------------------*/
	/* ------------------------- ARRAY ------------------------*/
	/* --------------------------------------------------------*/

	/*
	switches two array elements
	*/
	static swapArrayElements(array, a, b) {
	    var temp = array[a];
	    array[a] = array[b];
	    array[b] = temp;
	}

	/*
	Removes one or more objects from an array
	*/
	static removeFromArray(array, from, to?){
		var rest = array.slice((to || from) + 1 || array.length);
	  	array.length = from < 0 ? array.length + from : from;
	  	return array.push.apply(array, rest);
	}

	/*
	Shuffles an array and returns the new, shuffled array
	*/
	static shuffleArray(array) {
	    for (var i = array.length - 1; i > 0; i--) {
	        var j = Math.floor(Math.random() * (i + 1));
	        var temp = array[i];
	        array[i] = array[j];
	        array[j] = temp;
	    }
	    return array;
	}

	static bezier:any = {
		ease: "ease",
		smoothstep: "ease",
		"in": "ease-in",
		out: "ease-out",
		"in-out": "ease-in-out",
		snap: "cubic-bezier(0,1,.5,1)",
		easeOutCubic: "cubic-bezier(.215,.61,.355,1)",
		easeInOutCubic: "cubic-bezier(.645,.045,.355,1)",
		easeInCirc: "cubic-bezier(.6,.04,.98,.335)",
		easeOutCirc: "cubic-bezier(.075,.82,.165,1)",
		easeInOutCirc: "cubic-bezier(.785,.135,.15,.86)",
		easeInExpo: "cubic-bezier(.95,.05,.795,.035)",
		easeOutExpo: "cubic-bezier(.19,1,.22,1)",
		easeInOutExpo: "cubic-bezier(1,0,0,1)",
		easeInQuad: "cubic-bezier(.55,.085,.68,.53)",
		easeOutQuad: "cubic-bezier(.25,.46,.45,.94)",
		easeInOutQuad: "cubic-bezier(.455,.03,.515,.955)",
		easeInQuart: "cubic-bezier(.895,.03,.685,.22)",
		easeOutQuart: "cubic-bezier(.165,.84,.44,1)",
		easeInOutQuart: "cubic-bezier(.77,0,.175,1)",
		easeInQuint: "cubic-bezier(.755,.05,.855,.06)",
		easeOutQuint: "cubic-bezier(.23,1,.32,1)",
		easeInOutQuint: "cubic-bezier(.86,0,.07,1)",
		easeInSine: "cubic-bezier(.47,0,.745,.715)",
		easeOutSine: "cubic-bezier(.39,.575,.565,1)",
		easeInOutSine: "cubic-bezier(.445,.05,.55,.95)",
		easeInBack: "cubic-bezier(.6,-.28,.735,.045)",
		easeOutBack: "cubic-bezier(.175, .885,.32,1.275)",
		easeInOutBack: "cubic-bezier(.68,-.55,.265,1.55)"
	};
}

class Stopwatch{
 	
 	public interval:any;
 	public offset:any;
 	public clock:any;
 	public options:any = {
 		delay:100
 	};
	public container:any;
	public time:any;
	public curTime:any;


	constructor(container){
		this.container = container;  

		this.time = this.container.find('.time');
		// initialize
		this.reset();
	}	
  	
  
    public start() {

    	console.log("Start Stopwatch");
      	if (!this.interval) {
      		this.clock = 0;
      		this.render();
        	this.offset   = Date.now();
        	this.interval = setInterval($.proxy(this.update, this), this.options.delay);
    	}
  	}
  
  	public stop() {
  		console.log("Stop Stopwatch");
    	if (this.interval) {
      		clearInterval(this.interval);
      		this.interval = null;
    	}		
  	}
  
  	public reset() {
    	this.clock = 0;
    	// this.render();
  	}
  
  	public update() {
    	this.clock += this.delta();
    	this.render();
  	}
  
  	public render() {

  		this.curTime = this.clock/1000;
  		this.curTime = this.curTime.toFixed(2);
    	this.time.html(this.curTime); 
    	
  	}
  
  	public delta() {
    	var now = Date.now(),
        	d   = now - this.offset;
    	this.offset = now;
    	return d;
  	}
}