module Nickel{

	export class Header{

		public container:any;
		public menuContainer:any;
		public showTimeout:any;
		public leftMenuOn:boolean = false;

		constructor(container){
			
			this.container = document.getElementById(container);
			this.menuContainer = document.getElementById('menuLeft');

			$(".viewTrigger").bind('click', this.viewClicked);

			var e = (Modernizr.touch) ? 'touchstart' : 'click';
			$("#btnMenuMobile").bind(e, $.proxy(this.menuClicked, this));

			$('.btnShareFacebook').bind('click', $.proxy(this.fbClicked, this));
			$('.btnShareTwitter').bind('click', $.proxy(this.twitterClicked, this));
			$('.btnShareGoogle').bind('click', $.proxy(this.googleClicked, this));

		}

		public fbClicked(){
			Share.onFacebook(Main.config.facebookShareLink, Main.shareCopy.facebookTitle, Main.shareCopy.globalFacebook);
			GA.trackEvent('Header', 'Click', 'Share_FB');
		}

		public twitterClicked(){
			Share.onTwitter(Main.config.facebookShareLink, Main.shareCopy.globalTwitter);
			GA.trackEvent('Header', 'Click', 'Share_TW');
		}

		public googleClicked(){
			Share.onGoogle(Main.config.facebookShareLink);
			GA.trackEvent('Header', 'Click', 'Share_G+');
		}

		private menuClicked(e){

			console.log("Menu clicked")
			// e.preventDefault();
			// e.stopImmediatePropagation();
			// e.stopPropagation();

			if(this.leftMenuOn ){
				this.hideLeftMenu();
			}else{
				this.showLeftMenu();
			}
			// this.leftMenuOn =! this.leftMenuOn;
		}

		private docClicked(e){

			e.preventDefault();
			e.stopPropagation();
			this.resetContent();
		}

		public resetContent(){

			// setTimeout( function(){ $(".first").css('margin-top', '45px'); }, 500);
			$("#container").removeClass('offRight');
			$("#container").unbind('click', $.proxy(this.docClicked, this));
		}

		private showLeftMenu(){

			this.menuContainer.style.display = 'block';
			$("#container").addClass('offRight');
			
			clearTimeout(this.showTimeout);

			$("#container").bind('click', $.proxy(this.docClicked, this));
			this.leftMenuOn = true;
		}

		private hideLeftMenu(){

			clearTimeout(this.showTimeout);
			this.showTimeout = setTimeout( ()=>{
				this.menuContainer.style.display = 'none';
			}, 500);

			this.resetContent();
			this.leftMenuOn = false;
		}

		public addToBar(view){

				
		}

		private viewClicked(e){

			e.preventDefault();

			var url = $(this).data("url");
			Utils.pushState(url);
		}

		public showMe(){
			this.container.classList.add('showMe');
		}

		public hideMe(){
			this.container.classList.remove('showMe');
		}
	}
}