///<reference path='../def/greensock.d.ts'/>
///<reference path='../def/jquery.d.ts'/>
///<reference path='../def/ga.d.ts'/>
///<reference path='../def/facebook.d.ts'/>
///<reference path='../def/modernizr.d.ts'/>
///<reference path='../def/history.d.ts'/>
///<reference path='../def/socket.io.d.ts'/>
///<reference path='../def/custom.d.ts'/>

///<reference path='./scaffolding/Scaffold.ts'/>
///<reference path='./scaffolding/Component.ts'/>
///<reference path='./scaffolding/View.ts'/>
///<reference path='./scaffolding/Header.ts'/>
///<reference path='./scaffolding/Footer.ts'/>
///<reference path='./scaffolding/Utils.ts'/>
///<reference path='./scaffolding/GoogleAnalytics.ts'/>
///<reference path='./scaffolding/Loader.ts'/>
///<reference path='./scaffolding/EventBus.ts'/>
///<reference path='./scaffolding/Ajax.ts'/>
///<reference path='./scaffolding/Share.ts'/>
///<reference path='./scaffolding/VirtualScroll.ts'/>

//Test Views
///<reference path='./views/LandingView.ts'/>
///<reference path='./views/AboutView.ts'/>
///<reference path='./views/PrologueView.ts'/>

class Main{

	static config:any; //public config variables passed in from the php
	static shareCopy:any = {
		"globalFacebook":"Immerse yourself and a friend into the blurred reality of personalized story telling.",
		"facebookTitle":"Hidden Window",
		"globalTwitter":"Immerse yourself and a friend into the blurred reality of personalized story telling. #HiddenWindow. ",
		"globalGoogle":"Immerse yourself and a friend into the blurred reality of personalized story telling. #HiddenWindow. ",
		"itemTitle":"Hidden Window",
		"itemTwitter":"Hidden Window"
	}

	static appUrlApple:string;
	static appUrlGoogle:string;
	static appTarg:string;
	static ourOS:any;
	static player:any;

	private atY:number = 0;
	private maxY:number = 40;
	private state:string = "";
	private sWidth:number;

	

	/*
	Add the config variables and do any more global stuff here
	*/
	constructor(config){
		
		//set the config variables
		Main.config = config;
		Main.ourOS = Utils.detectMobileOS();

		//TODO replace with actual download links
		Main.appUrlApple = "https://itunes.apple.com/us/app/hidden-window/id925591215?mt=8";
		Main.appUrlGoogle  =  "https://play.google.com/store/apps/details?id=com.jnickel.hiddenwindow";
		Main.appTarg = (Main.ourOS.os == "iOS")? "itunes_store" : "_blank";

		Main.config.storyId = "1f0da875-221a-4450-ee4e-e8c76a70554a"; //Hidden window
		Main.config.actId = "0ebabd34-a5cb-4341-e63e-41ed09ac1fa1"; //Prologue
		Main.config.sceneId = '245f1f7d-7a12-4dd5-cca3-80110e860666'; //Intro video
		Main.config.videoInventoryId = 'b80f7a26-b07e-4bf1-b0c7-bcca952b32e3'; //Surveillance Video

		// var scroller = new Nickel.VirtualScroll();
		// scroller.maxY = this.maxY;
		// EventBus.addEventListener('UPDATE_POSITION', $.proxy(this.updatePos, this));
		// scroller.attach();

		History['Adapter'].bind(window,'statechange',$.proxy(this.stateChanged, this));
		$(window).bind('resize orientationchange', $.proxy(this.resize, this));
		this.resize();
	}

	private stateChanged(){
		var urlStrings = History['getState']().url.split("/");
		var stateString = urlStrings[3];
		this.state = stateString;
	}

	private resize(){

		this.sWidth = $(window).width();
	}

	private updatePos(e){

		this.atY = e.scrollY;
		if(this.sWidth > 700){
			if(this.atY == 0 && this.state != ""){
				Utils.pushState('/');
			}else if(e.scrollY == this.maxY && this.state !="about"){
				Utils.pushState('/about');
			}
		}	
	}
}

