module Nickel{
	
	export class Footer extends Component{

		constructor(content, data, delegate){

			super(content, data, delegate);
			
			History['Adapter'].bind(window,'statechange',$.proxy(this.stateChanged, this))
		}

		private stateChanged(){
			var urlStrings = History['getState']().url.split("/");
			var stateString = urlStrings[3];

			if(stateString == "" && this.onStage){
				this.hideMe();
			}else if(stateString == "about" && !this.onStage){
				this.showMe();
			}
		}
	}
}