
module Nickel{
	
	export class LandingView extends View{

		public displayTimeout:any;

		constructor(container, data, id){

			super(container, data, id);

			if(Main.ourOS.os == "Android"){
				this.content.find('#btnApple').hide();
			}else if(Main.ourOS.os == "iOS"){
				this.content.find('#btnGoogle').hide();
			}
			$('.googleUrl').attr('href', Main.appUrlGoogle);
			$('.appleUrl').attr('href', Main.appUrlApple);
			$('.downloadLink').attr('target', Main.appTarg);
			$('.playbutton').click($.proxy(this.playClicked,this));
			$('.closebtn').click($.proxy(this.closeClicked,this));

			$(window).bind('resize orientationchange', $.proxy(this.resize, this));
			this.resize();
		}

		public resize(){

			var sWidth = $(window).width();
			var sHeight = $(window).height();
			var min = 800;
			var h = (sWidth > 700) ? sHeight + 300 : sHeight;
			if(h < min) h = min;
			// this.content.css('height', sHeight)

			$("#youtube").css('height', this.content.find('.content').width() / (1280/720));
		}
		public playClicked() {
			//hide elements, play video
			this.hideUI();
			// $(.)
			// Main.player.playVideo();
			// Main.player.seekTo(0);
		}
		public closeClicked() {
			//hide elements, play video
			this.showUI();
			// Main.player.stopVideo();
		}
		public showUI() {
			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout(()=>{
					$('.background').css('opacity',1);
					$('.hoodie').css('opacity',1);
					$('.content').css('opacity',1);	
				},25);
			$('.background').css('display','block');
			$('.hoodie').css('display','block');
			$('.content').css('display','block');	
			$('.closebtn').css('display','none');	
		}

		public hideUI() {
			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout(()=>{
					$('.background').css('display','none');
					$('.hoodie').css('display','none');
					$('.content').css('display','none');	
				},400);
			
				$('.background').css('opacity',0);
				$('.hoodie').css('opacity',0);
				$('.content').css('opacity',0);	
				$('.closebtn').css('display','block');	
		}
	}
}