
module Nickel{
	
	export class PrologueView extends View{

		public socket:any;
		public myFakeFacebookId:any;
		public waitingForVideo:boolean =  false;
		public videoNode:any;
		public videoPlayer:any;
		public padding:number = 20;

		constructor(container, data, id){

			super(container, data, id);

			this.videoNode = $("#video");

			if(Main.config.browser == "firefox"){

				$("#videoPlayer").addClass('video-js').addClass('vjs-default-skin');

				var options = { 
					"autoplay": true,
					"controls": true,
					"techOrder":['flash','html5']
				}

				this.videoPlayer = videojs('videoPlayer', options);
			}

			$(window).bind('resize orientationchange', $.proxy(this.resize, this));
			this.resize();

			if(!Modernizr.touch) this.padding = 0;

			this.socket = io.connect('https://cms.imposium.com');
			this.socket.on('gotScene', $.proxy(this.gotScene, this));
			this.socket.on('gotMessage', $.proxy(this.updateMessage, this));
			this.socket.on('connect', function(){

			});

			$("#btnClose").bind('click', $.proxy(this.closeVideo, this));
			$('#uploadVideoInput').bind('change', $.proxy(this.videoInputChanged, this));
			$("#btnStart").bind('click', $.proxy(this.generateUserAndStart, this));
			$("#btnUploadVideo").bind('click', $.proxy(this.uploadClicked, this));
			$(".videoUploadForm").bind('submit' ,$.proxy(this.generateUserAndStart, this));

			History['Adapter'].bind(window,'statechange',$.proxy(this.stateChanged, this))
		}

		public closeVideo(){

			Utils.pushState("/");
		}

		public uploadClicked(){
			$("#uploadVideoInput").click();
		}

		public stateChanged(){

			var state = History['getState']();
			var arr = state.url.split("/");
			var stateStr = arr[3];
			var subStr = (arr[4]) ?  ( arr[4].length > 0) ?  arr[4] : null : null;

			if(stateStr == "v" && subStr && !this.waitingForVideo){

				this.startEventProcessor(subStr);
				this.showVideo(null);
			}

			if(!stateStr){
				// $('#loader').hide();
				this.hideVideo();

			}
		}

		public videoInputChanged(e){
			//show spinner
			$("#spinner").show();
			$("#btnUploadVideo").html('Change Video').addClass('change');
			this.showStart();

			var fileName = $("#uploadVideoInput").val().split('/').pop().split('\\').pop();
			$("#fileName").html(fileName);
			
		}

		public showStart(){
			$("#btnStart").css({
				'pointer-events':'auto',
				'visibility':'visible'
			});
		}

		public hideStart(){
			$("#btnStart").css({
				'pointer-events':'none',
				'visibility':'hidden'
			});
		}

		public updateMessage(data){
			$("#msg").css('visibility', 'visible');
			$(".message").html(data.msg);
		}

		public generateUserAndStart(e){

			e.preventDefault();
			e.stopPropagation();

			//check for a name
			var name = $("#nameInput").val();
			var fileName =  $("#uploadVideoInput").val().split('/').pop().split('\\').pop();	

			$("#nameError").empty();

			if(name != "" && name!=null && name!=undefined && fileName != "" && fileName!=null && fileName!=undefined){

				$(".videoUploadForm").unbind('submit' ,$.proxy(this.generateUserAndStart, this));
				this.updateMessage({'msg':'uploading video...'});
				$("#progress").css('visibility', 'visible');
				$("#nameError").css('visibility', 'hidden')
				this.hideStart();
				var gender = null;
				var name = name;
				var picture = null;
				var accessToken = null;
				this.myFakeFacebookId = Utils.generateUUID();

				Ajax.post(new AjaxRequest({
					'task':'login',
					'name':name,
					'auth':1,
					'id':this.myFakeFacebookId,
					'access_token':accessToken,
					'gender':gender,
					'profile_picture': picture
				}, $.proxy(this.getUserExperiences, this)));	
			}else if(name == "" || name==null || name==undefined){
				$("#nameError").css('visibility', 'visible').append('Please enter a name<br>');
			}else if(fileName == "" || fileName==null || fileName==undefined){
				$("#nameError").css('visibility', 'visible').append('Please upload a video');
			}
			

		}

		public getUserExperiences(loginResponse){

			var data = {
	    		'task' : 'get-user-experiences',
	    		'user_id' : loginResponse.bind.user.id
	    	}

	    	Ajax.post(new AjaxRequest(data, $.proxy(this.addUserExperience, this, loginResponse.bind.user.id)));
		}

		public addUserExperience(userId, experiencesResponse){

			var name = $("#nameInput").val();
			var arr = name.split(" ");
			var fn = (arr[0]) ? arr[0] : "John";
			var ln = (arr[arr.length-1]) ? arr[arr.length-1] : "Doe";

			var data = {
				'task' : 'add-user-experience',
				'user_id' : userId,
				'story_id' : Main.config.storyId,
				'friend_first_name':fn,
				'friend_last_name':ln,
				'friend_name':fn+' '+ln,
				'first_name':fn,
				'last_name':ln,
				'name':fn+' '+ln,
				'friend_social_id':this.myFakeFacebookId
			};

			Ajax.post(new AjaxRequest(data, $.proxy(this.updateInventory, this)));
		}


		public updateInventory(newExpResponse){

			var expId = (typeof newExpResponse === 'object') ? newExpResponse.bind.experience_id : newExpResponse;
			var form = $('.videoUploadForm');
			var invStr = '{"'+Main.config.videoInventoryId+'":""}';

			$('#uploadVideoInput').attr('name',Main.config.videoInventoryId);
			form.find('input[type=hidden][name=inventory]').val(invStr);
			form.find('input[type=hidden][name=experience_id]').val(expId);

			Ajax.formSubmit(form,$.proxy(this.startEventProcessor,this), $.proxy(this.formProgress, this));
		}

		public formProgress(evt, pos, total, perc){

			var bWidth = 200;
			$("#progress .bar").css('width', bWidth * (perc / 100));
		}

		public startEventProcessor(newExpResponse){

			$("#progress").css('visibility', 'hidden');

			$('#loader').show();
			//start the event processor

			var expId = (typeof newExpResponse === 'object') ? newExpResponse.bind.experience_id : newExpResponse;
			var data = {'expId' : expId, 'actId' : Main.config.actId, 'trigger' : ''};
			this.socket.emit('sendSocket', data);

			this.waitingForVideo = true;
			Utils.pushState('/v/' + expId);
		}

		public gotScene(data){

			this.waitingForVideo = false;
			var vidUrl = null;
			if (data.hasOwnProperty('output')) {
				if(typeof data.output != 'undefined'){

					for(var key in data.output){
						vidUrl = data.output[key].mp4Url;
						break;
					}
				}
			}
			if(vidUrl != null) {
				this.showVideo(vidUrl);
			}
		}

		public showVideo(src){

			var format = "mp4";
			this.videoNode.addClass("active");
			this.videoNode.show();	

			if(src){
				if(this.videoPlayer){
					this.videoPlayer.src([
					  { type: "video/mp4", src: src },
					]);
				} else {
					this.videoNode.find('video').html('<source src="'+src+'" type="video/'+format+'">').load();
					this.videoNode.find('video').get(0).play();
				}
			}
			this.resize();
		}

		public hideVideo(){
			this.videoNode.find('video').get(0).pause();
			this.videoNode.hide();
		}

		public resize(){

			var sWidth = $(window).width();
			var sHeight = $(window).height();

			var vWidth = 1920;
			var vHeight = 1080;
			var r = vWidth / vHeight;

			// if(Modernizr.touch){
				var w = sWidth - (this.padding*2);
				var h = w / r;
				if(h - (this.padding*2) > sHeight ){
					h = sHeight - (this.padding*2);
					w = h * r;
				}
				var l = (sWidth - w)  / 2;
				var t = (sHeight - h)  / 2;
				var top = (sHeight-h) / 2 - 40;
				if(top < 0) top = 0;	
			// }
			$("#btnClose").css('top', top);

			this.videoNode.find('video').css({
				'width':w,
				'height':h,
				'left':l,
				'top':t
			});

			if(this.videoPlayer){
				$("#videoPlayer").css({
					'width':w,
					'height':h,
					'left':l,
					'top':t,
					'position':'absolute'
				});
				this.videoPlayer.dimensions( w, h );
			}
		}
	}
}