module Nickel{

	/*
	Parent class for all url triggered dynamic views
	*/
	export class View{

		/************** Global Variables **************/

		//Template variables, do not edit!
		public id:string; //the id unique to this view
		public data:any; //the text for the header to trigger this view
		public content:any; //the jquery object content for this view
		public onStage:boolean = true; //remembers if this view is currently on the stage
		public subSection:any = null; //the active sub section for this view

		//Config variables
		public hideDelay:any = 0;
		public loaded:boolean = false;
		
		public displayTimeout:any;
		public animationTimeout:any;
		public trigger:any;
		public initilized:boolean = false;
		public rivets:any;

		/************** Functions **************/

		/*
		Stores the global vars, and adds the state change listener
		*/
		constructor(content, data, id){

			//set the variables passed in from the parent object
			this.content = content;
			this.id = id; 
			this.data = data;

			//if this view has a unique id, add a url change listener
			// if(id != null) History['Adapter'].bind(window,'statechange',$.proxy(this.stateChanged, this));

			this.addToHeader();
		}

		private addToHeader(){

			this.trigger = $('<li>' + this.data.displayText + '</li>');	
			$('#links').append(this.trigger);
			this.trigger.bind('click', $.proxy(this.triggerClicked, this));
		}

		public triggerClicked(e){

			e.preventDefault();
			Utils.pushState('/' +this.id);
		}

		/*
		Triggered anytime the url changes in the browser
		*/
		public stateChanged() {

			//get the state
			var showView = false;
			var urlStrings = History['getState']().url.split("/");
			var stateString = urlStrings[3];
			var subString = (urlStrings[4]) ?  ( urlStrings[4].length > 0) ?  urlStrings[4] : null : null;

			//if we are just updating the sub class
			if(stateString ==this.id && this.onStage && this.subSection != subString){
				this.updateMe(subString);

			//check to see if we need to hide this class
			}else if(stateString != this.id && this.onStage){
				this.hideMe(stateString);

			//check to see if we need to show the class
			}else if(stateString == this.id && !this.onStage){
				this.showMe(subString);
			}
		}

		/*
		Triggered if this view is off the stage and it's id comes up in the url
		*/
		public showMe(fromState){
			
	
			if(!this.initilized){
				console.log("Init: " + this.data.displayText);
				this.init();
				return;
			}

			console.log("Show: " + this.data.displayText);

			//display the view's content
			if(this.trigger) this.trigger.addClass('active');
			this.content.css('display', 'block');
			this.onStage = true;

			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout( ()=>{
				this.showPageContent();
			}, 30);

			if(this.trigger) this.trigger.find('a').addClass('active');
		}

		public init(){

			this.rivets = rivets.bind(this.content, {
				"data":this.data
			});

			this.initilized = true;
			this.showMe(null);
		}

		/*
		Triggered if this view is on the stage, and the url changes to anoter view's id
		*/
		public hideMe(state?){

			// console.log("Hide Me: " + this.id);

			this.hidePageContent();
				
			clearTimeout(this.displayTimeout);
			this.displayTimeout = setTimeout( ()=>{
				this.onStage = false;	
				// this.content.css('display', 'none');
			}, this.hideDelay);

			if(this.trigger) this.trigger.removeClass('active');
		}

		public updateMe(sString){

			this.subSection = sString;
		}

		/*
		Toggles the show and hide css classes to show the content	
		*/
		public showPageContent(){
			this.content.removeClass('hideContent');
			this.content.addClass('showContent');
		}

		/*
		Toggles the show and hide css classes to hide the content
		*/
		public hidePageContent(){
			this.content.removeClass('showContent');
			this.content.addClass('hideContent');
		}

	}
}