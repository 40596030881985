var Scaffold = (function () {
    function Scaffold() {
        this.assignments = [];
    }
    Scaffold.prototype.selector = function () {
        // Override me
        return null;
    };
    Scaffold.selector = function () {
        // Override me
        return null;
    };
    Scaffold.prototype.getClassName = function () {
        var funcNameRegex = /function (.{1,})\(/;
        var results = (funcNameRegex).exec(this.constructor.toString());
        return (results && results.length > 1) ? results[1] : "";
    };
    Scaffold.construct = function (className, parameters) {
        var scaffold = Object.create(window[className].prototype);
        scaffold.constructor.apply(scaffold, parameters);
        return scaffold;
    };
    Scaffold.assign = function (selector, method, type, object) {
        if (type === void 0) { type = 'click'; }
        if (object === void 0) { object = null; }
        if (!object) {
            object = this;
        }
        var fullSelector = '';
        var elementId = object.selector().attr('id');
        if (elementId) {
            fullSelector += '#' + elementId;
        }
        else {
            fullSelector += object.selector().selector;
        }
        if (selector.trim() != '') {
            fullSelector += ' ' + selector;
        }
        var fullMethod = $.proxy(method, object);
        $(document).on(type, fullSelector, fullMethod);
        if (object != this) {
            object.assignments.push([type, fullSelector, fullMethod]);
        }
        return object;
    };
    Scaffold.prototype.assign = function (selector, method, type) {
        if (type === void 0) { type = 'click'; }
        return Scaffold.assign(selector, method, type, this);
    };
    Scaffold.prototype.checkboxAreaToggle = function (checkBoxSelector) {
        this.assign(checkBoxSelector, function (e) {
            if ($(e.target).is(':checked')) {
                $(checkBoxSelector + '-area').removeClass('none');
            }
            else {
                $(checkBoxSelector + '-area').addClass('none');
            }
        }, 'change');
        return this;
    };
    Scaffold.prototype.acceptEnter = function (textFieldSelector) {
        var last = this.assignments[this.assignments.length - 1];
        this.assign(textFieldSelector, function (e) {
            if (e.which == 13 && last[2]) {
                last[2]();
            }
        }, 'keypress');
        return this;
    };
    Scaffold.prototype.unassignAll = function () {
        for (var i = 0; i < this.assignments.length; i++) {
            var ass = this.assignments[i];
            $(document).off(ass[0], ass[1], ass[2]);
        }
        this.assignments = [];
        return this;
    };
    return Scaffold;
})();
var Nickel;
(function (Nickel) {
    var Component = (function () {
        //child stuff
        // public childToDelete:any;
        // public children:any = [];
        // public activeChild:any;
        function Component(container, data, delegate) {
            this.onStage = false;
            this.hideDelay = 400;
            this.showDelay = 500;
            this.container = container;
            this.delegate = delegate;
            this.data = data;
            this.viewLoaded(this.container);
        }
        //created the rivets data binding and binds all Level generic events
        Component.prototype.viewLoaded = function (e) {
            this.content = e;
            if (this.container)
                this.container.append(this.content);
            this.rivets = rivets.bind(this.content, {
                "data": this.data,
                "controller": this
            });
            $(window).bind("resize", $.proxy(this.resize, this));
            this.resize(null);
        };
        Component.prototype.showMe = function (delay) {
            var _this = this;
            var dly = (delay) ? delay : this.showDelay;
            this.content.css('display', 'block');
            this.onStage = true;
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.showPageContent();
            }, dly);
        };
        Component.prototype.hideMe = function () {
            var _this = this;
            this.hidePageContent();
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.onStage = false;
                _this.content.css('display', 'none');
            }, this.hideDelay);
        };
        Component.prototype.showPageContent = function () {
            this.content.removeClass('hideContent');
            this.content.addClass('showContent');
        };
        /*
        Toggles the show and hide css classes to hide the content
        */
        Component.prototype.hidePageContent = function () {
            this.content.removeClass('showContent');
            this.content.addClass('hideContent');
        };
        //removed this classes frontend from the UI
        Component.prototype.killMe = function () {
            this.rivets.unbind();
            this.content.remove();
            this.content = null;
        };
        //OVERRIDE ME!
        Component.prototype.resize = function (e) {
        };
        return Component;
    })();
    Nickel.Component = Component;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    /*
    Parent class for all url triggered dynamic views
    */
    var View = (function () {
        /************** Functions **************/
        /*
        Stores the global vars, and adds the state change listener
        */
        function View(content, data, id) {
            this.onStage = true; //remembers if this view is currently on the stage
            this.subSection = null; //the active sub section for this view
            //Config variables
            this.hideDelay = 0;
            this.loaded = false;
            this.initilized = false;
            //set the variables passed in from the parent object
            this.content = content;
            this.id = id;
            this.data = data;
            //if this view has a unique id, add a url change listener
            // if(id != null) History['Adapter'].bind(window,'statechange',$.proxy(this.stateChanged, this));
            this.addToHeader();
        }
        View.prototype.addToHeader = function () {
            this.trigger = $('<li>' + this.data.displayText + '</li>');
            $('#links').append(this.trigger);
            this.trigger.bind('click', $.proxy(this.triggerClicked, this));
        };
        View.prototype.triggerClicked = function (e) {
            e.preventDefault();
            Utils.pushState('/' + this.id);
        };
        /*
        Triggered anytime the url changes in the browser
        */
        View.prototype.stateChanged = function () {
            //get the state
            var showView = false;
            var urlStrings = History['getState']().url.split("/");
            var stateString = urlStrings[3];
            var subString = (urlStrings[4]) ? (urlStrings[4].length > 0) ? urlStrings[4] : null : null;
            //if we are just updating the sub class
            if (stateString == this.id && this.onStage && this.subSection != subString) {
                this.updateMe(subString);
            }
            else if (stateString != this.id && this.onStage) {
                this.hideMe(stateString);
            }
            else if (stateString == this.id && !this.onStage) {
                this.showMe(subString);
            }
        };
        /*
        Triggered if this view is off the stage and it's id comes up in the url
        */
        View.prototype.showMe = function (fromState) {
            var _this = this;
            if (!this.initilized) {
                console.log("Init: " + this.data.displayText);
                this.init();
                return;
            }
            console.log("Show: " + this.data.displayText);
            //display the view's content
            if (this.trigger)
                this.trigger.addClass('active');
            this.content.css('display', 'block');
            this.onStage = true;
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.showPageContent();
            }, 30);
            if (this.trigger)
                this.trigger.find('a').addClass('active');
        };
        View.prototype.init = function () {
            this.rivets = rivets.bind(this.content, {
                "data": this.data
            });
            this.initilized = true;
            this.showMe(null);
        };
        /*
        Triggered if this view is on the stage, and the url changes to anoter view's id
        */
        View.prototype.hideMe = function (state) {
            // console.log("Hide Me: " + this.id);
            var _this = this;
            this.hidePageContent();
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.onStage = false;
                // this.content.css('display', 'none');
            }, this.hideDelay);
            if (this.trigger)
                this.trigger.removeClass('active');
        };
        View.prototype.updateMe = function (sString) {
            this.subSection = sString;
        };
        /*
        Toggles the show and hide css classes to show the content
        */
        View.prototype.showPageContent = function () {
            this.content.removeClass('hideContent');
            this.content.addClass('showContent');
        };
        /*
        Toggles the show and hide css classes to hide the content
        */
        View.prototype.hidePageContent = function () {
            this.content.removeClass('showContent');
            this.content.addClass('hideContent');
        };
        return View;
    })();
    Nickel.View = View;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var Header = (function () {
        function Header(container) {
            this.leftMenuOn = false;
            this.container = document.getElementById(container);
            this.menuContainer = document.getElementById('menuLeft');
            $(".viewTrigger").bind('click', this.viewClicked);
            var e = (Modernizr.touch) ? 'touchstart' : 'click';
            $("#btnMenuMobile").bind(e, $.proxy(this.menuClicked, this));
            $('.btnShareFacebook').bind('click', $.proxy(this.fbClicked, this));
            $('.btnShareTwitter').bind('click', $.proxy(this.twitterClicked, this));
            $('.btnShareGoogle').bind('click', $.proxy(this.googleClicked, this));
        }
        Header.prototype.fbClicked = function () {
            Share.onFacebook(Main.config.facebookShareLink, Main.shareCopy.facebookTitle, Main.shareCopy.globalFacebook);
            GA.trackEvent('Header', 'Click', 'Share_FB');
        };
        Header.prototype.twitterClicked = function () {
            Share.onTwitter(Main.config.facebookShareLink, Main.shareCopy.globalTwitter);
            GA.trackEvent('Header', 'Click', 'Share_TW');
        };
        Header.prototype.googleClicked = function () {
            Share.onGoogle(Main.config.facebookShareLink);
            GA.trackEvent('Header', 'Click', 'Share_G+');
        };
        Header.prototype.menuClicked = function (e) {
            console.log("Menu clicked");
            // e.preventDefault();
            // e.stopImmediatePropagation();
            // e.stopPropagation();
            if (this.leftMenuOn) {
                this.hideLeftMenu();
            }
            else {
                this.showLeftMenu();
            }
            // this.leftMenuOn =! this.leftMenuOn;
        };
        Header.prototype.docClicked = function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.resetContent();
        };
        Header.prototype.resetContent = function () {
            // setTimeout( function(){ $(".first").css('margin-top', '45px'); }, 500);
            $("#container").removeClass('offRight');
            $("#container").unbind('click', $.proxy(this.docClicked, this));
        };
        Header.prototype.showLeftMenu = function () {
            this.menuContainer.style.display = 'block';
            $("#container").addClass('offRight');
            clearTimeout(this.showTimeout);
            $("#container").bind('click', $.proxy(this.docClicked, this));
            this.leftMenuOn = true;
        };
        Header.prototype.hideLeftMenu = function () {
            var _this = this;
            clearTimeout(this.showTimeout);
            this.showTimeout = setTimeout(function () {
                _this.menuContainer.style.display = 'none';
            }, 500);
            this.resetContent();
            this.leftMenuOn = false;
        };
        Header.prototype.addToBar = function (view) {
        };
        Header.prototype.viewClicked = function (e) {
            e.preventDefault();
            var url = $(this).data("url");
            Utils.pushState(url);
        };
        Header.prototype.showMe = function () {
            this.container.classList.add('showMe');
        };
        Header.prototype.hideMe = function () {
            this.container.classList.remove('showMe');
        };
        return Header;
    })();
    Nickel.Header = Header;
})(Nickel || (Nickel = {}));
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    __.prototype = b.prototype;
    d.prototype = new __();
};
var Nickel;
(function (Nickel) {
    var Footer = (function (_super) {
        __extends(Footer, _super);
        function Footer(content, data, delegate) {
            _super.call(this, content, data, delegate);
            History['Adapter'].bind(window, 'statechange', $.proxy(this.stateChanged, this));
        }
        Footer.prototype.stateChanged = function () {
            var urlStrings = History['getState']().url.split("/");
            var stateString = urlStrings[3];
            if (stateString == "" && this.onStage) {
                this.hideMe();
            }
            else if (stateString == "about" && !this.onStage) {
                this.showMe();
            }
        };
        return Footer;
    })(Nickel.Component);
    Nickel.Footer = Footer;
})(Nickel || (Nickel = {}));
/*
Helper functions accessable by all other .ts files
*/
var Utils = (function () {
    function Utils() {
    }
    Utils.isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    Utils.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    };
    Utils.ajaxifyForm = function (form, onComplete) {
        if (onComplete === void 0) { onComplete = false; }
        if (!form.data('ajaxified')) {
            form.data('ajaxified', '1');
            form.ajaxForm({
                complete: function (xhr) {
                    if (onComplete) {
                        var responseObj = eval("(" + xhr.responseText + ")");
                        onComplete(responseObj);
                    }
                }
            });
        }
        return form;
    };
    Utils.clientTimezone = function (date) {
        return date.addHours(-(new Date().getTimezoneOffset() / 60));
    };
    Utils.composite = function (under, alphaUnder, over, alphaOver) {
        return Math.round((under * alphaUnder) + (over * alphaOver));
    };
    Utils.multiply = function (topValue, bottomValue) {
        return topValue * bottomValue / 255;
    };
    Utils.overlay = function (a, b) {
        a /= 255;
        b /= 255;
        var result = 0;
        if (a < 0.5)
            result = 2 * a * b;
        else
            result = 1 - 2 * (1 - a) * (1 - b);
        return Math.min(255, Math.max(0, result * 255 | 0));
    };
    Utils.hardLight = function (a, b) {
        return Utils.overlay(b, a);
    };
    Utils.softLight = function (a, b) {
        a /= 255;
        b /= 255;
        var v = (1 - 2 * b) * (a * a) + 2 * b * a;
        return Utils.clamp(v * 255, 0, 255);
    };
    Utils.dodge = function (a, b) {
        return Math.min(256 * a / (255 - b + 1), 255);
    };
    Utils.burn = function (a, b) {
        return 255 - Math.min(256 * (255 - a) / (b + 1), 255);
    };
    Utils.divide = function (a, b) {
        return Math.min(256 * a / (b + 1), 255);
    };
    Utils.screen = function (a, b) {
        return 255 - (255 - b) * (255 - a) / 255;
    };
    Utils.grainExtract = function (a, b) {
        return Utils.clamp(a - b + 128, 0, 255);
    };
    Utils.grainMerge = function (a, b) {
        return Utils.clamp(a + b - 128, 0, 255);
    };
    Utils.difference = function (a, b) {
        return Math.abs(a - b);
    };
    Utils.addition = function (a, b) {
        return Math.min(a + b, 255);
    };
    Utils.substract = function (a, b) {
        return Math.max(a - b, 0);
    };
    Utils.darkenOnly = function (a, b) {
        return Math.min(a, b);
    };
    Utils.lightenOnly = function (a, b) {
        return Math.max(a, b);
    };
    Utils.color = function (a, b) {
        var aHSL = Utils.rgbToHsl(a);
        var bHSL = Utils.rgbToHsl(b);
        return Utils.hslToRgb(bHSL[0], bHSL[1], aHSL[2]);
    };
    Utils.hue = function (a, b) {
        var aHSV = Utils.rgbToHsv(a);
        var bHSV = Utils.rgbToHsv(b);
        if (!bHSV[1])
            return Utils.hsvToRgb(aHSV[0], aHSV[1], aHSV[2]);
        else
            return Utils.hsvToRgb(bHSV[0], aHSV[1], aHSV[2]);
    };
    Utils.value = function (a, b) {
        var aHSV = Utils.rgbToHsv(a);
        var bHSV = Utils.rgbToHsv(b);
        return Utils.hsvToRgb(aHSV[0], aHSV[1], bHSV[2]);
    };
    Utils.saturation = function (a, b) {
        var aHSV = Utils.rgbToHsv(a);
        var bHSV = Utils.rgbToHsv(b);
        return Utils.hsvToRgb(aHSV[0], bHSV[1], aHSV[2]);
    };
    Utils.rgbToHsl = function (r, g, b) {
        if (r instanceof Array) {
            b = r[2];
            g = r[1];
            r = r[0];
        }
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;
        if (max == min) {
            h = s = 0; // achromatic
        }
        else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return [h, s, l];
    };
    Utils.hslToRgb = function (h, s, l) {
        var r, g, b;
        if (s == 0) {
            r = g = b = l; // achromatic
        }
        else {
            function hue2rgb(p, q, t) {
                if (t < 0)
                    t += 1;
                if (t > 1)
                    t -= 1;
                if (t < 1 / 6)
                    return p + (q - p) * 6 * t;
                if (t < 1 / 2)
                    return q;
                if (t < 2 / 3)
                    return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }
            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }
        return [r * 255 | 0, g * 255 | 0, b * 255 | 0];
    };
    Utils.rgbToHsv = function (r, g, b) {
        if (r instanceof Array) {
            b = r[2];
            g = r[1];
            r = r[0];
        }
        r = r / 255, g = g / 255, b = b / 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, v = max;
        var d = max - min;
        s = max == 0 ? 0 : d / max;
        if (max == min) {
            h = 0; // achromatic
        }
        else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return [h, s, v];
    };
    Utils.hsvToRgb = function (h, s, v) {
        var r, g, b;
        var i = Math.floor(h * 6);
        var f = h * 6 - i;
        var p = v * (1 - s);
        var q = v * (1 - f * s);
        var t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v, g = t, b = p;
                break;
            case 1:
                r = q, g = v, b = p;
                break;
            case 2:
                r = p, g = v, b = t;
                break;
            case 3:
                r = p, g = q, b = v;
                break;
            case 4:
                r = t, g = p, b = v;
                break;
            case 5:
                r = v, g = p, b = q;
                break;
        }
        return [r * 255 | 0, g * 255 | 0, b * 255 | 0];
    };
    /* --------------------------------------------------------*/
    /* ------------------------ HELPERS -----------------------*/
    /* --------------------------------------------------------*/
    Utils.isOdd = function (num) {
        return (num % 2) == 1;
    };
    /*
    Used by the create overlay function to achieve the multiply blending in the canvas
    */
    Utils.step = function (e, t) {
        return (t >= e) ? 1 : 0;
    };
    Utils.minWithSign = function (v, m) {
        var s = (v < 0) ? -1 : 1;
        var vv = Math.min(Math.abs(v), m);
        return vv * s;
    };
    Utils.maxWithSign = function (v, m) {
        var s = (v < 0) ? -1 : 1;
        var vv = Math.max(Math.abs(v), m);
        return vv * s;
    };
    Utils.clamp = function (s, e, t) {
        if (t < s)
            return s;
        if (t > e)
            return e;
        return t;
    };
    Utils.smoothStep = function (e0, e1, t) {
        if (t <= e0)
            return e0;
        if (t >= e1)
            return e1;
        t = (t - e0) / (e1 - e0);
        return e0 + (e1 - e0) * (3 * t * t - 2 * t * t * t);
    };
    Utils.easeQuadOut = function (e0, e1, t) {
        return e0 + (e1 - e0) * (t * (2 - t));
    };
    /*
    opens a window centered in the screen
    */
    Utils.openWindow = function (url, width, height) {
        var windowSize = { 'width': width, 'height': height, 'left': (screen.width / 2) - (width / 2), 'top': (screen.height / 2) - (height / 2 + 100) };
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        window.open(url, '' + new Date().getTime() + '', windowFeatures);
    };
    /*
    helper for ajax requests
    */
    Utils.ajax = function (data, successCallback, errorCallback, ajaxUrl) {
        if (successCallback === void 0) { successCallback = null; }
        if (errorCallback === void 0) { errorCallback = null; }
        if (ajaxUrl === void 0) { ajaxUrl = null; }
        $.ajax({
            type: 'POST',
            url: 'ajax.cms.php',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorCallback) {
                    errorCallback(jqXHR.responseText);
                }
            }
        });
    };
    /*
    Pushes a string to the url
    */
    Utils.pushState = function (state) {
        // History['pushState']({state:1}, Main.config.pageTitle, state);
        History['pushState']({ state: 1 }, Main.config.title, state);
    };
    Utils.replaceState = function (state) {
        // History.replaceState(null, null, state);
    };
    /*
    Returns either iOS, Android, or Unknown, and the version number
    */
    Utils.detectMobileOS = function () {
        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;
        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        }
        else if (ua.match(/Android/i)) {
            mobileOS = 'Android';
            uaindex = ua.indexOf('Android ');
        }
        else {
            mobileOS = 'unknown';
        }
        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        }
        else if (mobileOS === 'Android' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 8, 3);
        }
        else {
            mobileOSver = 'unknown';
        }
        var num = Number(mobileOSver);
        return { "os": mobileOS, "ver": num };
    };
    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/
    /*
    Sets a cookie to the document object
    */
    Utils.setCookie = function (c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = value + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
        document.cookie = c_name + "=" + c_value;
    };
    /*
    Retrives a cookie from the document object using the cookies name
    */
    Utils.getCookie = function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    };
    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/
    /*
    Validates an email address
    */
    Utils.realEmail = function (addressToTest) {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    };
    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/
    /*
    Convert degrees to radians
    */
    Utils.degreesToRadians = function (degrees) {
        return degrees * Math.PI / 180;
    };
    /*
    convert radians to degrees
    */
    Utils.radiansToDegrees = function (radians) {
        return radians * 180 / Math.PI;
    };
    /*
    calculates the distance between two point's : p = {x:1, y:2}
    */
    Utils.lineDistance = function (point1, point2) {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    };
    /*
    calculates the angle in degrees between two points
    */
    Utils.calcAngle = function (x1, x2, y1, y2) {
        var calcAngle = Math.atan2(x1 - x2, y1 - y2) * (180 / Math.PI);
        if (calcAngle < 0)
            calcAngle = Math.abs(calcAngle);
        else
            calcAngle = 360 - calcAngle;
        return calcAngle;
    };
    /*
    returns a number between or on the two numbers specified
    */
    Utils.randomFromInterval = function (from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    };
    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/
    /*
    switches two array elements
    */
    Utils.swapArrayElements = function (array, a, b) {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
    };
    /*
    Removes one or more objects from an array
    */
    Utils.removeFromArray = function (array, from, to) {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    };
    /*
    Shuffles an array and returns the new, shuffled array
    */
    Utils.shuffleArray = function (array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
    Utils.bezier = {
        ease: "ease",
        smoothstep: "ease",
        "in": "ease-in",
        out: "ease-out",
        "in-out": "ease-in-out",
        snap: "cubic-bezier(0,1,.5,1)",
        easeOutCubic: "cubic-bezier(.215,.61,.355,1)",
        easeInOutCubic: "cubic-bezier(.645,.045,.355,1)",
        easeInCirc: "cubic-bezier(.6,.04,.98,.335)",
        easeOutCirc: "cubic-bezier(.075,.82,.165,1)",
        easeInOutCirc: "cubic-bezier(.785,.135,.15,.86)",
        easeInExpo: "cubic-bezier(.95,.05,.795,.035)",
        easeOutExpo: "cubic-bezier(.19,1,.22,1)",
        easeInOutExpo: "cubic-bezier(1,0,0,1)",
        easeInQuad: "cubic-bezier(.55,.085,.68,.53)",
        easeOutQuad: "cubic-bezier(.25,.46,.45,.94)",
        easeInOutQuad: "cubic-bezier(.455,.03,.515,.955)",
        easeInQuart: "cubic-bezier(.895,.03,.685,.22)",
        easeOutQuart: "cubic-bezier(.165,.84,.44,1)",
        easeInOutQuart: "cubic-bezier(.77,0,.175,1)",
        easeInQuint: "cubic-bezier(.755,.05,.855,.06)",
        easeOutQuint: "cubic-bezier(.23,1,.32,1)",
        easeInOutQuint: "cubic-bezier(.86,0,.07,1)",
        easeInSine: "cubic-bezier(.47,0,.745,.715)",
        easeOutSine: "cubic-bezier(.39,.575,.565,1)",
        easeInOutSine: "cubic-bezier(.445,.05,.55,.95)",
        easeInBack: "cubic-bezier(.6,-.28,.735,.045)",
        easeOutBack: "cubic-bezier(.175, .885,.32,1.275)",
        easeInOutBack: "cubic-bezier(.68,-.55,.265,1.55)"
    };
    return Utils;
})();
var Stopwatch = (function () {
    function Stopwatch(container) {
        this.options = {
            delay: 100
        };
        this.container = container;
        this.time = this.container.find('.time');
        // initialize
        this.reset();
    }
    Stopwatch.prototype.start = function () {
        console.log("Start Stopwatch");
        if (!this.interval) {
            this.clock = 0;
            this.render();
            this.offset = Date.now();
            this.interval = setInterval($.proxy(this.update, this), this.options.delay);
        }
    };
    Stopwatch.prototype.stop = function () {
        console.log("Stop Stopwatch");
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    };
    Stopwatch.prototype.reset = function () {
        this.clock = 0;
        // this.render();
    };
    Stopwatch.prototype.update = function () {
        this.clock += this.delta();
        this.render();
    };
    Stopwatch.prototype.render = function () {
        this.curTime = this.clock / 1000;
        this.curTime = this.curTime.toFixed(2);
        this.time.html(this.curTime);
    };
    Stopwatch.prototype.delta = function () {
        var now = Date.now(), d = now - this.offset;
        this.offset = now;
        return d;
    };
    return Stopwatch;
})();
var GA = (function () {
    function GA() {
    }
    GA.trackPage = function () {
        _gaq.push(['_trackPageview']);
    };
    GA.trackEvent = function (category, action, label) {
        _gaq.push(['_trackEvent', category, action, label]);
    };
    return GA;
})();
var Loader = (function () {
    function Loader(view) {
        this.view = view;
        this.init();
    }
    Loader.prototype.init = function () {
        this.hide();
    };
    Loader.prototype.show = function () {
        this.view.removeClass('hidden');
    };
    Loader.prototype.hide = function () {
        this.view.addClass('hidden');
    };
    return Loader;
})();
var EventBus = (function () {
    function EventBus() {
    }
    EventBus.addEventListener = function (evt, callback) {
        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = [];
        }
        if (EventBus.listeners[evt].indexOf(callback) == -1) {
            EventBus.listeners[evt].push(callback);
        }
    };
    EventBus.removeEventListener = function (evt, callback) {
        var i = EventBus.listeners[evt].indexOf(callback);
        if (i > -1) {
            EventBus.listeners[evt].splice(i, 1);
            if (EventBus.listeners[evt].length == 0) {
                EventBus.listeners[evt] = null;
            }
        }
    };
    EventBus.dispatchEvent = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        if (listeners) {
            for (var i = 0; i < listeners.length; i++) {
                listeners[i](data);
            }
        }
    };
    EventBus.listeners = {};
    return EventBus;
})();
var AjaxRequest = (function (_super) {
    __extends(AjaxRequest, _super);
    function AjaxRequest(data, success, error, loader, url) {
        if (data === void 0) { data = {}; }
        if (success === void 0) { success = null; }
        if (error === void 0) { error = null; }
        if (loader === void 0) { loader = null; }
        if (url === void 0) { url = 'https://cms.imposium.com/api/1.0'; }
        _super.call(this);
        this.url = null;
        this.data = {};
        this.successCallback = null;
        this.errorCallback = null;
        this.loader = null;
        this.url = url;
        this.data = data;
        this.successCallback = success;
        this.errorCallback = error;
        this.loader = loader;
    }
    return AjaxRequest;
})(Scaffold);
var Ajax = (function (_super) {
    __extends(Ajax, _super);
    function Ajax() {
        _super.apply(this, arguments);
    }
    Ajax.formSubmit = function (form, onComplete, onProgress, loader) {
        if (onComplete === void 0) { onComplete = null; }
        if (onProgress === void 0) { onProgress = null; }
        if (loader === void 0) { loader = null; }
        if (!form.data('ajaxified')) {
            form.data('ajaxified', '1');
            form.ajaxForm({
                complete: function (xhr) {
                    if (loader) {
                        loader.hide();
                    }
                    if (onComplete) {
                        var responseObj = eval("(" + xhr.responseText + ")");
                        onComplete(responseObj);
                    }
                },
                uploadProgress: function (evt, pos, total, perc) {
                    if (onProgress) {
                        onProgress(evt, pos, total, perc);
                    }
                }
            });
        }
        if (loader) {
            loader.show();
        }
        form.submit();
        return form;
    };
    Ajax.post = function (request) {
        if (request.loader) {
            request.loader.show();
        }
        $.ajax({
            type: 'POST',
            url: request.url,
            data: request.data,
            dataType: 'json',
            success: function (response) {
                if (request.loader) {
                    request.loader.hide();
                }
                Ajax.checkExpiredSession(response.bind.task);
                if (request.successCallback) {
                    request.successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (request.loader) {
                    request.loader.hide();
                }
                if (request.errorCallback) {
                    request.errorCallback(jqXHR.responseText);
                }
            }
        });
    };
    Ajax.checkExpiredSession = function (task) {
        if (task == 'expired') {
            Ajax.expireSession();
        }
    };
    Ajax.expireSession = function () {
        window.location.href = "index.php?id=lostsession";
    };
    return Ajax;
})(Scaffold);
var Share = (function () {
    function Share() {
    }
    Share.onTwitter = function (shareUrl, tweetWithoutUrl) {
        //build tweet URL
        var url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(shareUrl) + '&text=' + encodeURIComponent(tweetWithoutUrl);
        //open tweet dialog
        Utils.openWindow(url, 550, 480);
    };
    Share.onGoogle = function (shareURL) {
        var url = "https://plus.google.com/share?url=" + shareURL;
        Utils.openWindow(url, 600, 600);
    };
    Share.onTumblr = function (shareURL) {
        var encodedShareURL = encodeURIComponent(shareURL);
        $.ajax({
            url: '/php/get_media_detail.php',
            type: 'POST',
            data: {
                'type': 'json',
                'url': shareURL
            },
            success: function (data) {
                var url = "https://www.tumblr.com/share/link?url=" + encodedShareURL;
                if (data.title) {
                    url += '&name=' + encodeURIComponent(data.title);
                }
                if (data.desc) {
                    url += '&description=' + encodeURIComponent(data.desc);
                }
                Utils.openWindow(url, 450, 428);
            },
            dataType: 'json',
            async: false // Not async otherwise it falls in the popup blocker
        });
    };
    Share.onFacebook = function (shareUrl, title, description, message) {
        if (message === void 0) { message = null; }
        var obj = {
            method: 'share',
            href: shareUrl,
            display: 'popup'
        };
        function callback(response) {
        }
        FB.ui(obj, callback);
    };
    Share.viaEmail = function (subject, body) {
        subject = encodeURIComponent(subject);
        body = encodeURIComponent(body);
        var s = ('mailto:?subject=' + subject + '&body=' + body);
        window.location = s;
    };
    return Share;
})();
var Nickel;
(function (Nickel) {
    var VirtualScroll = (function () {
        function VirtualScroll() {
            this.multiplier = 0.7;
            this.easing = 0.1;
            this.minX = null;
            this.maxX = null;
            this.minY = 0;
            this.maxY = 2000;
            this.lastPageX = 0;
            this.lastPageY = 0;
            this.attached = false;
            this.autoPilotMode = false;
            this.anchors = [0];
            this.atAnchor = 0;
            this.buffer = 700;
            this.snapping = true;
            if (Main.config.browser == "firefox")
                this.multiplier = 0.3;
            // if (Browsers.chrome()) multiplier = 2;
            // if (Browsers.ie()) multiplier = 4;
            // if (FJ.Capabilities.touch) multiplier = 2;
            this.event = {
                scrollX: 0,
                scrollY: 0,
                targetScrollX: 0,
                targetScrollY: 0,
                deltaX: 0,
                deltaY: 0,
                maxDeltaX: 0,
                maxDeltaY: 0,
                screenHeight: 0,
                maxY: this.maxY
            };
            this.sHeight = $(window).height();
        }
        VirtualScroll.prototype.reset = function () {
            this.setValue(0, 0);
        };
        VirtualScroll.prototype.setValue = function (x, y) {
            this.event.scrollX = x;
            this.event.scrollY = y;
            this.event.targetScrollX = x;
            this.event.targetScrollY = y;
            this.autoPilotMode = false;
        };
        VirtualScroll.prototype.scrollTo = function (data) {
            this.autoPilotMode = true;
            this.startX = this.event.scrollX;
            this.startY = this.event.scrollY;
            this.event.targetScrollX = data.x;
            this.event.targetScrollY = data.y;
            // 30 frames = roughyl 0.5s by default (but it could be estimated based on distance-to-travel)
            this.duration = data.d || 30;
            this.t = 0;
        };
        VirtualScroll.prototype.autoScroll = function () {
            this.t++;
            var dy = Utils.easeQuadOut(this.startY, this.event.targetScrollY, this.t / this.duration);
            this._set(dy - this.event.scrollY);
            if (this.t >= this.duration) {
                this.autoPilotMode = false;
            }
        };
        VirtualScroll.prototype._set = function (dy) {
            if (this.easing && !this.autoPilotMode) {
                this.event.targetScrollY += dy;
            }
            else {
                this.event.scrollY += dy;
            }
            this.event.maxDeltaY = Math.max(this.event.maxDeltaY, Math.abs(dy));
            this.event.deltaY = dy;
            this.event.maxY = this.maxY;
            this.event.minY = this.minY;
            this.event.screenHeight = this.sHeight;
        };
        VirtualScroll.prototype.touch = function () {
            this._dispatch();
        };
        VirtualScroll.prototype._dispatch = function () {
            if (this.minX != null) {
                this.event.scrollX = Utils.clamp(this.minX, this.maxX, this.event.scrollX);
                this.event.targetScrollX = Utils.clamp(this.minX, this.maxX, this.event.targetScrollX);
            }
            if (this.minY != null) {
                this.event.scrollY = Utils.clamp(this.minY, this.maxY, this.event.scrollY);
                this.event.targetScrollY = Utils.clamp(this.minY, this.maxY, this.event.targetScrollY);
            }
            EventBus.dispatchEvent('UPDATE_POSITION', this.event);
        };
        VirtualScroll.prototype.attach = function () {
            // if (Modernizr.touch) {
            document.addEventListener('touchstart', $.proxy(this.onTouchStart, this), false);
            document.addEventListener('touchmove', $.proxy(this.onTouchMove, this), false);
            // } else {
            this.onWheelCallback = addWheelListener(document, $.proxy(this.onWheel, this), false); // this will throw a ts complier error
            // }
            requestAnimationFrame($.proxy(this.onFrame, this));
            // FJ.FrameImpulse.addEventListener($.proxy(this.onFrame, this)); ///TO DO
        };
        VirtualScroll.prototype.detach = function () {
            removeWheelListener(document, this.onWheelCallback);
            document.removeEventListener('touchstart', $.proxy(this.onTouchStart, this));
            document.removeEventListener('touchmove', $.proxy(this.onTouchMove, this));
        };
        VirtualScroll.prototype.onFrame = function () {
            var inc = (this.event.targetScrollY - this.event.scrollY);
            var oldY = this.event.scrollY;
            if (this.autoPilotMode) {
                this.autoScroll();
            }
            else if (this.easing) {
                // this.event.scrollX += (this.event.targetScrollX - this.event.scrollX) * this.easing;
                inc *= this.easing;
                this.event.scrollY += inc;
            }
            if (Math.abs(this.event.targetScrollY - this.event.scrollY) > 0.1 || this.autoPilotMode) {
                this._dispatch();
            }
            requestAnimationFrame($.proxy(this.onFrame, this));
        };
        VirtualScroll.prototype.onWheel = function (e) {
            if (!this.autoPilotMode) {
                var deltaY = (e.deltaY == e.deltaY >> 0) ? e.deltaY : e.deltaY * e.deltaY * e.deltaY;
                deltaY = Math.round(deltaY);
                if (deltaY < 0) {
                    deltaY = Math.max(deltaY, -100);
                }
                else {
                    deltaY = Math.min(deltaY, 100);
                }
                this._set(deltaY);
            }
        };
        VirtualScroll.prototype.onTouchStart = function (e) {
            this.lastPageX = 0;
            this.lastPageY = 0;
        };
        VirtualScroll.prototype.onTouchMove = function (e) {
            e.preventDefault();
            this.autoPilotMode = false;
            if (this.lastPageX != 0) {
                this._set(
                // -(e.targetTouches[0].pageX - this.lastPageX) * this.multiplier,
                -(e.targetTouches[0].pageY - this.lastPageY) * this.multiplier);
            }
            this.lastPageX = e.targetTouches[0].pageX;
            this.lastPageY = e.targetTouches[0].pageY;
        };
        return VirtualScroll;
    })();
    Nickel.VirtualScroll = VirtualScroll;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var LandingView = (function (_super) {
        __extends(LandingView, _super);
        function LandingView(container, data, id) {
            _super.call(this, container, data, id);
            if (Main.ourOS.os == "Android") {
                this.content.find('#btnApple').hide();
            }
            else if (Main.ourOS.os == "iOS") {
                this.content.find('#btnGoogle').hide();
            }
            $('.googleUrl').attr('href', Main.appUrlGoogle);
            $('.appleUrl').attr('href', Main.appUrlApple);
            $('.downloadLink').attr('target', Main.appTarg);
            $('.playbutton').click($.proxy(this.playClicked, this));
            $('.closebtn').click($.proxy(this.closeClicked, this));
            $(window).bind('resize orientationchange', $.proxy(this.resize, this));
            this.resize();
        }
        LandingView.prototype.resize = function () {
            var sWidth = $(window).width();
            var sHeight = $(window).height();
            var min = 800;
            var h = (sWidth > 700) ? sHeight + 300 : sHeight;
            if (h < min)
                h = min;
            // this.content.css('height', sHeight)
            $("#youtube").css('height', this.content.find('.content').width() / (1280 / 720));
        };
        LandingView.prototype.playClicked = function () {
            //hide elements, play video
            this.hideUI();
            // $(.)
            // Main.player.playVideo();
            // Main.player.seekTo(0);
        };
        LandingView.prototype.closeClicked = function () {
            //hide elements, play video
            this.showUI();
            // Main.player.stopVideo();
        };
        LandingView.prototype.showUI = function () {
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                $('.background').css('opacity', 1);
                $('.hoodie').css('opacity', 1);
                $('.content').css('opacity', 1);
            }, 25);
            $('.background').css('display', 'block');
            $('.hoodie').css('display', 'block');
            $('.content').css('display', 'block');
            $('.closebtn').css('display', 'none');
        };
        LandingView.prototype.hideUI = function () {
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                $('.background').css('display', 'none');
                $('.hoodie').css('display', 'none');
                $('.content').css('display', 'none');
            }, 400);
            $('.background').css('opacity', 0);
            $('.hoodie').css('opacity', 0);
            $('.content').css('opacity', 0);
            $('.closebtn').css('display', 'block');
        };
        return LandingView;
    })(Nickel.View);
    Nickel.LandingView = LandingView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var AboutView = (function (_super) {
        __extends(AboutView, _super);
        function AboutView(container, data, id) {
            _super.call(this, container, data, id);
            if (Main.ourOS.os == "Android") {
                this.content.find('#btnDownloadiOS').hide();
            }
            else if (Main.ourOS.os == "iOS") {
                this.content.find('#btnDownloadAndroid').hide();
            }
            $(window).bind('resize orientationchange', $.proxy(this.resize, this));
            this.resize();
            $('.googleUrl').attr('href', Main.appUrlGoogle);
            $('.appleUrl').attr('href', Main.appUrlApple);
            $('.downloadLink').attr('target', Main.appTarg);
        }
        AboutView.prototype.resize = function () {
            this.content.css('height', $(window).height());
            // this.content.css('top', $("#landingView").height());
            // if($(window).width() < 700 && this.onStage){
            // 	Utils.pushState("/");
            // }
        };
        return AboutView;
    })(Nickel.View);
    Nickel.AboutView = AboutView;
})(Nickel || (Nickel = {}));
var Nickel;
(function (Nickel) {
    var PrologueView = (function (_super) {
        __extends(PrologueView, _super);
        function PrologueView(container, data, id) {
            _super.call(this, container, data, id);
            this.waitingForVideo = false;
            this.padding = 20;
            this.videoNode = $("#video");
            if (Main.config.browser == "firefox") {
                $("#videoPlayer").addClass('video-js').addClass('vjs-default-skin');
                var options = {
                    "autoplay": true,
                    "controls": true,
                    "techOrder": ['flash', 'html5']
                };
                this.videoPlayer = videojs('videoPlayer', options);
            }
            $(window).bind('resize orientationchange', $.proxy(this.resize, this));
            this.resize();
            if (!Modernizr.touch)
                this.padding = 0;
            this.socket = io.connect('https://cms.imposium.com');
            this.socket.on('gotScene', $.proxy(this.gotScene, this));
            this.socket.on('gotMessage', $.proxy(this.updateMessage, this));
            this.socket.on('connect', function () {
            });
            $("#btnClose").bind('click', $.proxy(this.closeVideo, this));
            $('#uploadVideoInput').bind('change', $.proxy(this.videoInputChanged, this));
            $("#btnStart").bind('click', $.proxy(this.generateUserAndStart, this));
            $("#btnUploadVideo").bind('click', $.proxy(this.uploadClicked, this));
            $(".videoUploadForm").bind('submit', $.proxy(this.generateUserAndStart, this));
            History['Adapter'].bind(window, 'statechange', $.proxy(this.stateChanged, this));
        }
        PrologueView.prototype.closeVideo = function () {
            Utils.pushState("/");
        };
        PrologueView.prototype.uploadClicked = function () {
            $("#uploadVideoInput").click();
        };
        PrologueView.prototype.stateChanged = function () {
            var state = History['getState']();
            var arr = state.url.split("/");
            var stateStr = arr[3];
            var subStr = (arr[4]) ? (arr[4].length > 0) ? arr[4] : null : null;
            if (stateStr == "v" && subStr && !this.waitingForVideo) {
                this.startEventProcessor(subStr);
                this.showVideo(null);
            }
            if (!stateStr) {
                // $('#loader').hide();
                this.hideVideo();
            }
        };
        PrologueView.prototype.videoInputChanged = function (e) {
            //show spinner
            $("#spinner").show();
            $("#btnUploadVideo").html('Change Video').addClass('change');
            this.showStart();
            var fileName = $("#uploadVideoInput").val().split('/').pop().split('\\').pop();
            $("#fileName").html(fileName);
        };
        PrologueView.prototype.showStart = function () {
            $("#btnStart").css({
                'pointer-events': 'auto',
                'visibility': 'visible'
            });
        };
        PrologueView.prototype.hideStart = function () {
            $("#btnStart").css({
                'pointer-events': 'none',
                'visibility': 'hidden'
            });
        };
        PrologueView.prototype.updateMessage = function (data) {
            $("#msg").css('visibility', 'visible');
            $(".message").html(data.msg);
        };
        PrologueView.prototype.generateUserAndStart = function (e) {
            e.preventDefault();
            e.stopPropagation();
            //check for a name
            var name = $("#nameInput").val();
            var fileName = $("#uploadVideoInput").val().split('/').pop().split('\\').pop();
            $("#nameError").empty();
            if (name != "" && name != null && name != undefined && fileName != "" && fileName != null && fileName != undefined) {
                $(".videoUploadForm").unbind('submit', $.proxy(this.generateUserAndStart, this));
                this.updateMessage({ 'msg': 'uploading video...' });
                $("#progress").css('visibility', 'visible');
                $("#nameError").css('visibility', 'hidden');
                this.hideStart();
                var gender = null;
                var name = name;
                var picture = null;
                var accessToken = null;
                this.myFakeFacebookId = Utils.generateUUID();
                Ajax.post(new AjaxRequest({
                    'task': 'login',
                    'name': name,
                    'auth': 1,
                    'id': this.myFakeFacebookId,
                    'access_token': accessToken,
                    'gender': gender,
                    'profile_picture': picture
                }, $.proxy(this.getUserExperiences, this)));
            }
            else if (name == "" || name == null || name == undefined) {
                $("#nameError").css('visibility', 'visible').append('Please enter a name<br>');
            }
            else if (fileName == "" || fileName == null || fileName == undefined) {
                $("#nameError").css('visibility', 'visible').append('Please upload a video');
            }
        };
        PrologueView.prototype.getUserExperiences = function (loginResponse) {
            var data = {
                'task': 'get-user-experiences',
                'user_id': loginResponse.bind.user.id
            };
            Ajax.post(new AjaxRequest(data, $.proxy(this.addUserExperience, this, loginResponse.bind.user.id)));
        };
        PrologueView.prototype.addUserExperience = function (userId, experiencesResponse) {
            var name = $("#nameInput").val();
            var arr = name.split(" ");
            var fn = (arr[0]) ? arr[0] : "John";
            var ln = (arr[arr.length - 1]) ? arr[arr.length - 1] : "Doe";
            var data = {
                'task': 'add-user-experience',
                'user_id': userId,
                'story_id': Main.config.storyId,
                'friend_first_name': fn,
                'friend_last_name': ln,
                'friend_name': fn + ' ' + ln,
                'first_name': fn,
                'last_name': ln,
                'name': fn + ' ' + ln,
                'friend_social_id': this.myFakeFacebookId
            };
            Ajax.post(new AjaxRequest(data, $.proxy(this.updateInventory, this)));
        };
        PrologueView.prototype.updateInventory = function (newExpResponse) {
            var expId = (typeof newExpResponse === 'object') ? newExpResponse.bind.experience_id : newExpResponse;
            var form = $('.videoUploadForm');
            var invStr = '{"' + Main.config.videoInventoryId + '":""}';
            $('#uploadVideoInput').attr('name', Main.config.videoInventoryId);
            form.find('input[type=hidden][name=inventory]').val(invStr);
            form.find('input[type=hidden][name=experience_id]').val(expId);
            Ajax.formSubmit(form, $.proxy(this.startEventProcessor, this), $.proxy(this.formProgress, this));
        };
        PrologueView.prototype.formProgress = function (evt, pos, total, perc) {
            var bWidth = 200;
            $("#progress .bar").css('width', bWidth * (perc / 100));
        };
        PrologueView.prototype.startEventProcessor = function (newExpResponse) {
            $("#progress").css('visibility', 'hidden');
            $('#loader').show();
            //start the event processor
            var expId = (typeof newExpResponse === 'object') ? newExpResponse.bind.experience_id : newExpResponse;
            var data = { 'expId': expId, 'actId': Main.config.actId, 'trigger': '' };
            this.socket.emit('sendSocket', data);
            this.waitingForVideo = true;
            Utils.pushState('/v/' + expId);
        };
        PrologueView.prototype.gotScene = function (data) {
            this.waitingForVideo = false;
            var vidUrl = null;
            if (data.hasOwnProperty('output')) {
                if (typeof data.output != 'undefined') {
                    for (var key in data.output) {
                        vidUrl = data.output[key].mp4Url;
                        break;
                    }
                }
            }
            if (vidUrl != null) {
                this.showVideo(vidUrl);
            }
        };
        PrologueView.prototype.showVideo = function (src) {
            var format = "mp4";
            this.videoNode.addClass("active");
            this.videoNode.show();
            if (src) {
                if (this.videoPlayer) {
                    this.videoPlayer.src([
                        { type: "video/mp4", src: src },
                    ]);
                }
                else {
                    this.videoNode.find('video').html('<source src="' + src + '" type="video/' + format + '">').load();
                    this.videoNode.find('video').get(0).play();
                }
            }
            this.resize();
        };
        PrologueView.prototype.hideVideo = function () {
            this.videoNode.find('video').get(0).pause();
            this.videoNode.hide();
        };
        PrologueView.prototype.resize = function () {
            var sWidth = $(window).width();
            var sHeight = $(window).height();
            var vWidth = 1920;
            var vHeight = 1080;
            var r = vWidth / vHeight;
            // if(Modernizr.touch){
            var w = sWidth - (this.padding * 2);
            var h = w / r;
            if (h - (this.padding * 2) > sHeight) {
                h = sHeight - (this.padding * 2);
                w = h * r;
            }
            var l = (sWidth - w) / 2;
            var t = (sHeight - h) / 2;
            var top = (sHeight - h) / 2 - 40;
            if (top < 0)
                top = 0;
            // }
            $("#btnClose").css('top', top);
            this.videoNode.find('video').css({
                'width': w,
                'height': h,
                'left': l,
                'top': t
            });
            if (this.videoPlayer) {
                $("#videoPlayer").css({
                    'width': w,
                    'height': h,
                    'left': l,
                    'top': t,
                    'position': 'absolute'
                });
                this.videoPlayer.dimensions(w, h);
            }
        };
        return PrologueView;
    })(Nickel.View);
    Nickel.PrologueView = PrologueView;
})(Nickel || (Nickel = {}));
/* Copyright © 2016 Nickel Media, Inc. */
///<reference path='../def/greensock.d.ts'/>
///<reference path='../def/jquery.d.ts'/>
///<reference path='../def/ga.d.ts'/>
///<reference path='../def/facebook.d.ts'/>
///<reference path='../def/modernizr.d.ts'/>
///<reference path='../def/history.d.ts'/>
///<reference path='../def/socket.io.d.ts'/>
///<reference path='../def/custom.d.ts'/>
///<reference path='./scaffolding/Scaffold.ts'/>
///<reference path='./scaffolding/Component.ts'/>
///<reference path='./scaffolding/View.ts'/>
///<reference path='./scaffolding/Header.ts'/>
///<reference path='./scaffolding/Footer.ts'/>
///<reference path='./scaffolding/Utils.ts'/>
///<reference path='./scaffolding/GoogleAnalytics.ts'/>
///<reference path='./scaffolding/Loader.ts'/>
///<reference path='./scaffolding/EventBus.ts'/>
///<reference path='./scaffolding/Ajax.ts'/>
///<reference path='./scaffolding/Share.ts'/>
///<reference path='./scaffolding/VirtualScroll.ts'/>
//Test Views
///<reference path='./views/LandingView.ts'/>
///<reference path='./views/AboutView.ts'/>
///<reference path='./views/PrologueView.ts'/>
var Main = (function () {
    /*
    Add the config variables and do any more global stuff here
    */
    function Main(config) {
        this.atY = 0;
        this.maxY = 40;
        this.state = "";
        //set the config variables
        Main.config = config;
        Main.ourOS = Utils.detectMobileOS();
        //TODO replace with actual download links
        Main.appUrlApple = "https://itunes.apple.com/us/app/hidden-window/id925591215?mt=8";
        Main.appUrlGoogle = "https://play.google.com/store/apps/details?id=com.jnickel.hiddenwindow";
        Main.appTarg = (Main.ourOS.os == "iOS") ? "itunes_store" : "_blank";
        Main.config.storyId = "1f0da875-221a-4450-ee4e-e8c76a70554a"; //Hidden window
        Main.config.actId = "0ebabd34-a5cb-4341-e63e-41ed09ac1fa1"; //Prologue
        Main.config.sceneId = '245f1f7d-7a12-4dd5-cca3-80110e860666'; //Intro video
        Main.config.videoInventoryId = 'b80f7a26-b07e-4bf1-b0c7-bcca952b32e3'; //Surveillance Video
        // var scroller = new Nickel.VirtualScroll();
        // scroller.maxY = this.maxY;
        // EventBus.addEventListener('UPDATE_POSITION', $.proxy(this.updatePos, this));
        // scroller.attach();
        History['Adapter'].bind(window, 'statechange', $.proxy(this.stateChanged, this));
        $(window).bind('resize orientationchange', $.proxy(this.resize, this));
        this.resize();
    }
    Main.prototype.stateChanged = function () {
        var urlStrings = History['getState']().url.split("/");
        var stateString = urlStrings[3];
        this.state = stateString;
    };
    Main.prototype.resize = function () {
        this.sWidth = $(window).width();
    };
    Main.prototype.updatePos = function (e) {
        this.atY = e.scrollY;
        if (this.sWidth > 700) {
            if (this.atY == 0 && this.state != "") {
                Utils.pushState('/');
            }
            else if (e.scrollY == this.maxY && this.state != "about") {
                Utils.pushState('/about');
            }
        }
    };
    Main.shareCopy = {
        "globalFacebook": "Immerse yourself and a friend into the blurred reality of personalized story telling.",
        "facebookTitle": "Hidden Window",
        "globalTwitter": "Immerse yourself and a friend into the blurred reality of personalized story telling. #HiddenWindow. ",
        "globalGoogle": "Immerse yourself and a friend into the blurred reality of personalized story telling. #HiddenWindow. ",
        "itemTitle": "Hidden Window",
        "itemTwitter": "Hidden Window"
    };
    return Main;
})();
