class Share{
	
	static onTwitter(shareUrl,tweetWithoutUrl)
	{
		//build tweet URL
		var url = 'https://twitter.com/intent/tweet?url='+encodeURIComponent(shareUrl)+'&text='+encodeURIComponent(tweetWithoutUrl);

		//open tweet dialog
		Utils.openWindow(url,550,480);
	}

	static onGoogle(shareURL){

		var url = "https://plus.google.com/share?url=" + shareURL;
		Utils.openWindow(url,600,600);
	}

	static onTumblr(shareURL){
		var encodedShareURL = encodeURIComponent(shareURL);
		$.ajax({
			url: '/php/get_media_detail.php',
			type: 'POST',
			data: {
				'type': 'json',
				'url' : shareURL
			},
			success: function( data ) {
				var url = "https://www.tumblr.com/share/link?url="+encodedShareURL;
				if(data.title){
					url += '&name='+encodeURIComponent(data.title);
				}
				if(data.desc){
					url += '&description='+encodeURIComponent(data.desc);
				}
				Utils.openWindow(url,450,428);
			},
			dataType: 'json',
			async:false // Not async otherwise it falls in the popup blocker
		});
	}
	
	static onFacebook(shareUrl,title,description,message=null)
	{

        var obj = {
		  method: 'share',
		  href: shareUrl,
		  display:'popup'
		};

        function callback(response) {

        }

    	FB.ui(obj, callback);
	}

	static viaEmail(subject, body){

		subject = encodeURIComponent(subject);
		body = encodeURIComponent(body);
		var s:any = ('mailto:?subject='+subject+'&body='+body);
		window.location=s;
	}
}