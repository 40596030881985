
class Scaffold {
	private assignments = [];
	constructor() {}

	public selector()
	{
		// Override me
		return null;
	}

	static selector()
	{
		// Override me
		return null;
	}

	public getClassName() 
	{ 
        var funcNameRegex = /function (.{1,})\(/;
        var results = (funcNameRegex).exec((<any> this).constructor.toString());
        return (results && results.length > 1) ? results[1] : "";
    }

    static construct(className:string,parameters:any)
    {
    	var scaffold = Object.create(window[className].prototype);
		scaffold.constructor.apply(scaffold, parameters);
		return scaffold;
    }

    static assign(selector:string,method:any,type:string='click',object:any=null)
    {
    	if(!object){
    		object = this;
    	}

    	var fullSelector:string = '';
		var elementId = object.selector().attr('id');
		if(elementId){
			fullSelector += '#'+elementId;
		} else {
			fullSelector += object.selector().selector;
		}
		if(selector.trim() != ''){
			fullSelector += ' '+selector;
		}
		var fullMethod = $.proxy(method,object);
		$(document).on(type,fullSelector,fullMethod);
		
		if(object != this){
			object.assignments.push([type,fullSelector,fullMethod]);
		}
		
		return object;
    }

	public assign(selector:string,method:any,type:string='click')
	{
		return Scaffold.assign(selector,method,type,this);
	}

	public checkboxAreaToggle(checkBoxSelector:string)
	{
		this.assign(checkBoxSelector,function(e){
			if($(e.target).is(':checked')){
				$(checkBoxSelector+'-area').removeClass('none');
			} else {
				$(checkBoxSelector+'-area').addClass('none');
			}
		},'change');
		return this;
	}

	public acceptEnter(textFieldSelector:string)
	{
		var last = this.assignments[this.assignments.length-1];
		this.assign(textFieldSelector,function(e){
			if(e.which == 13 && last[2]){
				last[2]();
			}
		},'keypress');
		return this;
	}

	public unassignAll()
	{
		for(var i = 0; i < this.assignments.length; i++){
			var ass = this.assignments[i];
			$(document).off(ass[0],ass[1],ass[2]);
		}
		this.assignments = [];
		return this;
	}


}